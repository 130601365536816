// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("/Users/vrgz/WebstormProjects/baza/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-regulamin-js": () => import("/Users/vrgz/WebstormProjects/baza/src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-rejestracja-udana-wolo-js": () => import("/Users/vrgz/WebstormProjects/baza/src/pages/rejestracja_udana_wolo.js" /* webpackChunkName: "component---src-pages-rejestracja-udana-wolo-js" */),
  "component---src-pages-rejestracja-udana-js": () => import("/Users/vrgz/WebstormProjects/baza/src/pages/rejestracja_udana.js" /* webpackChunkName: "component---src-pages-rejestracja-udana-js" */),
  "component---src-pages-turnieje-js": () => import("/Users/vrgz/WebstormProjects/baza/src/pages/turnieje.js" /* webpackChunkName: "component---src-pages-turnieje-js" */),
  "component---src-pages-warsztaty-js": () => import("/Users/vrgz/WebstormProjects/baza/src/pages/warsztaty.js" /* webpackChunkName: "component---src-pages-warsztaty-js" */)
}

